import React from "react"
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom"
import { /*LayoutMain,*/ LayoutDashboard } from './layouts'
import PrivateRoute from './utils/PrivateRoute'
import PageDashboard from './pages/dashboard'
import InstructionsForUse from './pages/instructions'
import FluidMonitor from './pages/fluidMonitor'
import Privacy from './pages/privacy'
import Terms from './pages/terms'

const Routes = () => (
  <Switch>
    <PrivateRoute path="/" exact>
      <LayoutDashboard>
        <PageDashboard />
      </LayoutDashboard>
    </PrivateRoute>
    <PrivateRoute path="/instructions" exact>
      <LayoutDashboard>
        <InstructionsForUse />
      </LayoutDashboard>
    </PrivateRoute>
    <PrivateRoute path="/fluid-monitor" exact>
      <LayoutDashboard>
        <FluidMonitor />
      </LayoutDashboard>
    </PrivateRoute>
    <PrivateRoute path="/privacy" exact>
      <LayoutDashboard>
        <Privacy />
      </LayoutDashboard>
    </PrivateRoute>
    <PrivateRoute path="/terms" exact>
      <LayoutDashboard>
        <Terms />
      </LayoutDashboard>
    </PrivateRoute>
    <Route path="*">
      <Redirect to="/" />
    </Route>
  </Switch>
)

export default Routes
