import React from 'react'
import PropTypes from 'prop-types'
import Barcode128 from '../../components/barcode128'

const PageDashboard = ({
  children = null,
}) => {
  const barcodeString = '(01)9180000560030(8012)HE1.0.0'

  const contentWelcome = (
    <div className="flex-auto">
      <title className="flex justify-center text-2xl font-bold">Welcome RetInSight Customer!</title>
      <p className="flex justify-start mt-4 text-left">
        Thank you for obtaining a license to the RetInSight Fluid Monitor.
        Here you will find information about the practical use of Fluid Monitor and resources.
      </p>
    </div>
  )
  const contentDemoVideo = (
    <div className="w-full flex flex-row justify-center">
      <video width="750" height="500" controls>
        <source src={`${process.env.PUBLIC_URL}/movie/RetInSight_Animation.mp4`} type="video/mp4" />
      </video>
    </div>
  )
  const contentProductInfos = (
    <div className="flex-auto">
      <title className="flex justify-center font-bold">RetInSight Fluid Monitor</title>
      <div className="flex justify-center mt-4 text-left">
        <img
          src={`${process.env.PUBLIC_URL}/manufacturer.png`}
          className="h-10 w-140"
          alt="Manufacturer"
        />
        <p className="m-2">RetInSight GmbH, Elisabethstraße 13/1/13, 1010 Vienna, Austria</p>
      </div>
      <div className="flex justify-center flex-col md:flex-row mt-4 text-left">
        <div className="flex justify-center flex-row">
          <div>
          <img
            src={`${process.env.PUBLIC_URL}/ce.png`}
            className="h-12 w-12 mt-2 mr-6"
            alt="CE Mark"
          />
          </div>
          <div className="flex justify-center flex-col">
            <div className="h-10 w-14 mr-4 text-center">
              <img
                src={`${process.env.PUBLIC_URL}/instructions.png`}
                className="h-10 w-14"
                alt="IfU Symbol"
              />
            </div>
            <div className="h-10 w-14 pr-2 text-center">eIFU</div>
          </div>
        </div>
        <div>
          <Barcode128
            code={barcodeString}
            barcodeHeight={80}
            className="h-10 mt-1 text-center"
            alt="UDI Number"
            showCode
            fontStyle={{ fontSize: 14, }}
          />
        </div>
      </div>
    </div>
  )

  return (
    <>
      <div className="w-full">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="mt-5 p-5 mx-auto bg-white w-full">
            {contentWelcome}
          </div>
          <div className="mt-5 p-5 mx-auto bg-white">
            {contentDemoVideo}
          </div>
          <div className="my-5 p-5 mx-auto bg-white w-full">
            {contentProductInfos}
          </div>
        </div>
      </div>
      {children}
    </>
  )
}

PageDashboard.propTypes = {
  children: PropTypes.any,
}

export default PageDashboard
