import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { createLinkToDonloadIfUPdf } from '../../store/DataSlice'
import { RefreshIcon } from '@heroicons/react/solid'

const InstructionsForUse = ({
  children = null,
}) => {
  const [ ifuUrl, setIfuUrl ] = React.useState('')
  const dispatch = useDispatch()

  React.useEffect(()=>{
    (async () => {
      try {
        const response = await dispatch(createLinkToDonloadIfUPdf())
        setIfuUrl(response?.payload?.url)
      } catch (error) {
        console.log(error)
      }
    })()
  })

  return (
    <>
      <div as="nav" className="w-full">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="mt-10 pt-5 pb-2 px-5 mx-auto bg-white w-full">
            <div className="flex flex-col w-full">
              <title className="flex justify-start text-2xl">Instruction for Use</title>
              <div className="flex justify-start my-4 text-left break-words">
              { (ifuUrl)
                ? (
                  <span className="my-4 text-left break-words">
                    Please use the following link to
                    <a
                      className="px-1 underline text-blue-500 cursor-pointer"
                      onClick={() => window.open(ifuUrl)}
                    >
                      download
                    </a>
                    [.pdf]<br/>
                  </span>
                )
                :(
                  <div className="flex justify-left items-left ml-20 rounded-lg bg-white bg-opacity-75 w-full h-full">
                    <RefreshIcon className="self-center text-gray-300 flip-horizontal w-14 h-14 bg-transparent rounded-md spin-back" />
                  </div>
                )
              }
              </div>
            </div>
          </div>
        </div>
      </div>
      {children}
    </>
  )
}

InstructionsForUse.propTypes = {
  children: PropTypes.any,
}

export default InstructionsForUse
