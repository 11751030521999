import React from 'react'
import PropTypes from 'prop-types'

const Terms = ({
  children = null,
}) => {
  return (
    <>
      <div className="w-full">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="my-5 p-5 mx-auto bg-white w-full">
            <title className="flex justify-start text-3xl">Terms of Use</title>
            <div className="px-2 md:px-5 mx-auto bg-white w-full flex flex-col md:flex-row">
              <div className="text-left">
                <h3>RetInSight Fluid Monitor</h3>
                <h3>User Agreement</h3>
                <h5>01. DEFINITIONS</h5>
                <p>The following capitalized terms shall have the meaning set forth below.  Other defined terms shall have the meanings set forth where they are first underlined.</p>
                <ul className="page-content">
                  <li><div>1.1.</div><div>&#8220;Administrator Users&#8221; means each User employee designated by User to serve as administrator of the Fluid Monitor on User&#39;s behalf.</div></li>
                  <li><div>1.2.</div><div>&#8220;Fluid Monitor&#8221; means the proprietary RetInSight software solution offered as a cloud based service via the internet measuring fluid on digital OCT images.</div></li>
                  <li><div>1.3.</div><div>&#8220;Fluid Monitor Access&#8221; means access to the Fluid Monitor in the version described in the Schedule.</div></li>
                  <li><div>1.4.</div><div>&#8220;Fluid Monitor Support&#8221; means the support as specified in <a href="#">Annex 2</a> hereto.</div></li>
                  <li><div>1.5.</div><div>&#8220;Documentation&#8221; means reports or any other data or material, to be delivered to User by RetInSight.</div></li>
                  <li><div>1.6.</div><div>&#8220;Effective Date&#8221; is the upon which the User Agreement is signed by both parties.</div></li>
                  <li><div>1.7.</div><div className="ml-3">&#8220;Schedule&#8221; is a written document attached to this User Agreement under <a href="#">Annex 1</a> or executed separately by RetInSight and the User for the purpose of purchasing Fluid Monitor Services under the terms and conditions of this User Agreement.</div></li>
                  <li><div>1.8.</div><div>&#8220;User&#8221; means the entity named on the Schedule to which these terms and conditions are attached.</div></li>
                  <li><div>1.9.</div><div>&#8220;User Accounts&#8221; means the number of accounts agreed in the Schedule.</div></li>
                  <li><div>1.10</div><div>&#8220;Volumes&#8221; means the number of OCT scans that will be analysed by the Fluid Monitor subject to the Schedule.</div></li>
                </ul>
                <h5>02. FLUID MONITOR</h5>
                <ul className="page-content">
                  <li><div>2.1.</div><div className="ml-2">During the subscription term indicated in the Schedule, User will receive a nonexclusive, non-assignable, royalty free, worldwide right to access and use the Fluid Monitor solely for internal operations subject to the terms of this Agreement and up to the number of User Accounts and Volumes documented in the Schedule.</div></li>
                  <li><div>2.2.</div><div>User acknowledges that RetInSight will not be delivering copies of Fluid Monitor to User.</div></li>
                  <li><div>2.3.</div><div className="ml-1">Subject to the terms of the Schedule, RetInSight will provide Fluid Monitor access to the User by providing a password protected internet access. The Fluid Monitor will be hosted on a server operated by either RetInSight or a third party authorized by RetInSight to host the Fluid Monitor (hereinafter referred to as &#8220;Server&#8221;). RetInSight does not owe to User the installation or maintenance of an internet connection from User’s computer to the exchange node operated and designated by RetInSight or its contractors. Subject to reasonable prior notice to User, RetInSight or its contractors may change such exchange node at any time.</div></li>
                  <li><div>2.4.</div><div>RetInSight may at any time reasonably amend, modify, update, or upgrade the Fluid Monitor.</div></li>
                  <li><div>2.5.</div><div className="ml-1">User shall be responsible for maintaining copies of any images or other data, which User uploads to the Fluid Monitor. RetInSight shall store Materials uploaded as legally required and as needed for the methodological refinement of the Fluid Monitor and quality management. RetInSight may anonymize uploaded materials for analytical and research purposes.</div></li>
                </ul>
                <h5>03. RESTRICTIONS</h5>
                <ul className="page-content">
                  <li>
                    <div>3.1.</div>
                    <div>
                      <p className="mt-0">User shall not, and shall not permit anyone to: (i) copy or republish the Fluid Monitor, (ii) make the Fluid Monitor available to any person other than RetInSight authorized users, (iii) otherwise use the Fluid Monitor on a  service bureau basis, (iv) modify or create derivative works based upon the Fluid Monitor or related documentation, (v) remove, modify or obscure any copyright, trademark or other proprietary notices contained in the Fluid Monitor or in the documentation related to it, (vi) reverse engineer, decompile, disassemble, or otherwise attempt to derive the source code of the Fluid Monitor, or (vii) access the Fluid Monitor or use the documentation related to it in order to build a similar product or competitive product or (viii) use the Fluid Monitor, with or as part of, any clinical trials, any other clinical use, any investigational use, any for profit translational research, it being agreed that any such use, product or service may only be undertaken or offered subject to the terms of a mutually agreed to separate addendum or other written agreement between the parties.</p>
                      <p>Subject to the limited licenses granted herein, RetInSight shall own all right, title and interest in and to the Fluid Monitor Documentation, including all modifications, improvements, upgrades, derivative works and feedback related thereto and intellectual property rights therein. User agrees to assign all right, title and interest it may have in the foregoing to RetInSight.</p>
                    </div>
                  </li>
                </ul>
                <h5>04. USER MATERIALS AND DATA</h5>
                <ul className="page-content">
                  <li><div>4.1.</div><div className="ml-1">All documentation, data, information, images, and biological, medical and other materials furnished to RetInSight by or on behalf of User (“Materials”) and all associated intellectual property rights will remain the exclusive property of User.</div></li>
                  <li><div>4.2.</div><div>User confirms that it is legally entitled to provide and use Materials for the purpose of this User Agreement. User further acknowledges that all medical or other personal data provided to RetInSight shall be either anonymized or pseudonymized in accordance with applicable laws, regulations, and technical standards prior to sharing such data.</div></li>
                </ul>
                <h5>05. USER RESPONSIBILITIES</h5>
                <ul className="page-content">
                  <li><div>5.1.</div><div className="ml-1">User shall comply with all applicable local, state, national and foreign laws in connection with its use of the Fluid Monitor, including those laws related to data privacy, international communications, and the transmission of technical or personal data. User acknowledges that RetInSight exercises no control over the content of the information transmitted by User or its Administrators.</div></li>
                  <li><div>5.2.</div><div>User shall: (a) notify RetInSight immediately of any unauthorized use of any password or user id or any other known or suspected breach of security, (b) report to RetInSight immediately and use reasonable efforts to stop any unauthorized use that is known or suspected by User, and (c) not provide false identity information to gain access to or use the Fluid Monitor.</div></li>
                  <li><div>5.3.</div><div>User shall be solely responsible for the acts and omissions of its Administrator Users. RetInSight shall not be liable for any loss of data or functionality caused directly or indirectly by the Administrator Users.</div></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {children}
    </>
  )
}

Terms.propTypes = {
  children: PropTypes.any,
}

export default Terms
