import React from 'react'
import PropTypes from 'prop-types'

const Privacy = ({
  children = null,
}) => {
  return (
    <>
      <div className="w-full">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="my-5 p-5 mx-auto bg-white w-full">
            <title className="flex justify-start text-3xl">Privacy Policy</title>
            <div className="px-2 md:px-5 mx-auto bg-white w-full flex flex-col md:flex-row">
              <div className="text-left">
                <div aria-hidden="true"></div>
                <h3>01. Controller</h3>
                <p><strong>RetInSight GmbH</strong>, Elisabethstraße13/1/13 in 1010 Vienna, Austria, CEO: Corinna zur Bonsen-Thomas, Handelsgericht Wien, FN 531348w, T: +43-(0)1-5813881, <a href="mailto:corinna.thomas@retinsight.com">corinna.thomas@retinsight.com</a> is responsible for the processing of your personal data in accordance with Art. 4 sec. 7 General Data Protection Regulation.</p>
                <div aria-hidden="true"></div>
                <h3>02. Data protection officer</h3>
                <p>RetInSight GmbH has appointed the company <strong>rs consulting GmbH</strong>, Buchberggasse 6 in 3400 Klosterneuburg, Austria, CEO: Roland Schopper, Corporate Register Court Korneuburg, FN 411839x, T:+43(2243)22562, <a href="mailto:datenschutz@resultate.at">datenschutz@resultate.at</a> as external data protection officer.</p>
                <div aria-hidden="true"></div>
                <h3>03. Data categories and purpose of processing</h3>
                <p>The controller collects and processes different categories of personal data, depending on the purpose for the processing. The following sections show the categories of data by purpose of processing:</p>
                <div aria-hidden="true"></div>
                <h4>03.1 Your Visit of our website</h4>
                <div aria-hidden="true"></div>
                <h5>03.1.1 Log data of your visit</h5>
                <div aria-hidden="true"></div>
                <p>When you visit our website, we collect the following personal information transferred from your server:</p>
                <ul><li>IP address</li><li>The date and time of the request</li><li>Time zone difference based on Greenwich Mean Time (GMT)</li><li>Content of the request (specific website)</li><li>HTTP-Status-Code</li><li>Volume of data transmitted each time a visit</li><li>Website from which the request comes</li><li>Browser, including language and version</li><li>Operating system and interface</li><li>Visitor&#8217;s country of origin</li></ul>
                <div aria-hidden="true"></div>
                <h5>03.1.2 Cookies</h5>
                <div aria-hidden="true"></div>
                <p>When you visit our website, technically necessary cookies are stored on your computer. Cookies are small text files that are stored on your hard drive in conjunction with the browser you are using. They serve to make our offer more user-friendly and effective. This website uses different types of cookies in accordance with our cookie policy.</p>
                <div aria-hidden="true"></div>
                <h5>03.1.3 Analytics</h5>
                <div aria-hidden="true"></div>
                <p>For privacy reasons, we do not use web analytics services.</p>
                <div aria-hidden="true"></div>
                <h4>03.2 Your contact with us</h4>
                <div aria-hidden="true"></div>
                <p>If you contact us via a form on the website or by e-mail, your data will be stored with us for six months in order to process the request and in case of follow-up questions. We do not share this data without your consent.</p>
                <div aria-hidden="true"></div>
                <h4>03.3 Contract</h4>
                <div aria-hidden="true"></div>
                <p>For the purpose of contract processing, the following personal data will be stored with us: contact person, e-mail address, telephone number, contracts and other contractual documents. The data provided by you is necessary for the performance of the contract or for the implementation of pre-contractual measures. Without this data, we cannot conclude the contract with you.</p>
                <div aria-hidden="true"></div>
                <h4>03.4 Employee data</h4>
                <div aria-hidden="true"></div>
                <p>The details of the processing of the data of our employees can be found in the document &#8220;Employee Data Protection Declaration&#8221;, which is accessible for all employees and persons who intend to become an employee.</p>
                <div aria-hidden="true"></div>
                <h4>03.5 Biometric data in the form of OCT scans</h4>
                <div aria-hidden="true"></div>
                <p>As a processor, we process biometric data in the form of retinal OCT scans. This data will only be transmitted to us in pseudonymous form.</p>
                <p>The privilege of processing personal data goes hand in hand with our responsibility to treat this data ethically correct. We are committed to using data responsibly and transparently. RetInSight strives to adhere to high ethical standards in all areas of the company and to pursue an ethical, value-oriented culture in which problems are addressed quickly and visibly.</p>
                <p>RetInSight treats all data in accordance with national and international laws and guidelines, as well as ethical standards and principles.</p>
                <p>RetInSight processes image data that is classified as sensitive personal data, as the OCT images can be used to draw conclusions about the person&#8217;s health status. The protection of privacy is a central and sensitive issue both from a regulatory point of view and for RetInSight&#8217;s customers and cooperation partners. Retinal images are categorized as biometric data, since the unique vascular structure cannot be changed (similar to a fingerprint) and therefore can only be pseudonymized (not anonymized) according to current interpretation. RetInSight is aware that customer adoption of artificial intelligence (AI) technology is critical and develops and operates these algorithms in accordance with EU Code of Ethics for Trusted AI.</p>
                <p>Pseudonymization means that we as processor cannot assign the collected data to specific patients, and all personally identifiable information (PII) remains with the direct healthcare provider. In addition, full encryption is already included in the development process.</p>
                <p>Strict technical and organizational measures resulting from our data protection impact assessment ensure the highest level of privacy and security. All data is encrypted and processed only within the EU. The data sets are pseudonymized from the beginning and only a limited number of employees can access this data.</p>
                <p>We are aware of our special responsibility and apply the strictest standards to enable the highest level of security and data protection.</p>
                <div aria-hidden="true"></div>
                <h3>04 Purpose and legal basis of data processing</h3>
                <div aria-hidden="true"></div>
                <h4>04.1 Your visit at our website at <a href="http://www.retinsight.com">www.retinsight.com</a></h4>
                <div aria-hidden="true"></div>
                <p>We want to enable a user-friendly experience of our website, avoiding any technical faults or malfunctions. We also want to ensure that we do provide the most relevant information, which may require to take into account certain personal information, such as the language in your browser settings or the country from where you are visiting our Services. The legal basis for the presented processing of personal data is our legitimate interest, i.e. Art. 6 sec. 1 lit. f) GDPR.</p>
                <div aria-hidden="true"></div>
                <h4>04.2 Your contact with us</h4>
                <div aria-hidden="true"></div>
                <p>When you contact us, we process your personal data to respond to your request. The legal basis for the processing of your personal data is the initiation of a contractual relationship on the basis of your request or an existing contractual relationship with you and thus Art. 6 sec. 1 b) of the GDPR or the protection of our legitimate interests, i.e. Art. 6 sec. 1 lit. f) GDPR.</p>
                <div aria-hidden="true"></div>
                <h4>04.3 Contract</h4>
                <div aria-hidden="true"></div>
                <p>The data processing is necessary for the fulfilment of the contract and therefore takes place on the basis of the legal provisions of Art 6 (1) lit b of the GDPR.</p>
                <div aria-hidden="true"></div>
                <h3>05 Transfer to 3rd parties</h3>
                <p>Data is not transferred to third parties, with the exception of the settled banking institutions / payment service providers for the purpose of processing the payment transactions, to the transport company/shipping company commissioned by us for the delivery of any documents as well as to our tax advisor for the fulfilment of our tax obligations. In specific areas, we cooperate with selected processors with whom we have concluded corresponding agreements in accordance with the GDPR.</p>
                <div aria-hidden="true"></div>
                <h3>06 Storage periods for personal data</h3>
                <p>Your data will be deleted as soon as you withdraw your consent or in general, as soon as the purpose of the data processing has ceased. In some cases, we are required by law to keep data for a period of time. As soon as this period has elapsed, we will delete this data in accordance with the data protection obligations.</p>
                <div aria-hidden="true"></div>
                <h3>07 Social-Media Plug-ins</h3>
                <p>For privacy reasons, we do not currently use Social-Media plug-ins.</p>
                <div aria-hidden="true"></div>
                <h3>08 Integrated third-party service tools</h3>
                <p>Our website does not include third-party service tools.</p>
                <div aria-hidden="true"></div>
                <h3>09 Your rights</h3>
                <p>You are entitled to access, rectify, delete, restrict, data portability, revocation and opposition with regard to your data stored by us. If you believe that the processing of your data violates data protection law or your data protection claims have otherwise been violated in any way, you can complain to us or the data protectionauthority, in Austria this is the data protection authority under <a href="http://www.dsb.gv.at">www.dsb.gv.at</a>.</p>
                <p>Regardless of your rights, please contact us for any queries or suggestions via above mentioned channels (under item &#8220;Controller&#8221; or &#8220;Privacy Officer&#8221;) details! We look forward to hearing from you!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {children}
    </>
  )
}

Privacy.propTypes = {
  children: PropTypes.any,
}

export default Privacy
