import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon, XCircleIcon, RefreshIcon } from '@heroicons/react/solid'
import { fn } from '../../utils/utils'
import { ClipboardCopyIcon } from '@heroicons/react/outline'
import { resetToken } from '../../components/auth/store/userSlice'
import { useThrottle } from "rooks";

const ModalCreateFMPassword = ({
  open = false,
  onCancel = fn,
  onConfirm = fn,
  onOpen = true,
  // onSuccess = fn,
  data: {
    status,
    sub,
    appPassword,
  },
}) => {
  const dispatch = useDispatch()
  const buttonRefConfirmOk = React.useRef()
  const buttonRefConfirmCancel = React.useRef()
  const buttonRefSuccessCancel = React.useRef()
  const buttonRefErrorCancel = React.useRef()
  let [confirmationIsOpen, setConfirmationIsOpen] = React.useState(true)
  let [preloaderIsOpen, setPreloaderIsOpen] = React.useState(false)
  let [successIsOpen, setSuccessIsOpen] = React.useState(false)
  let [unauthorizedIsOpen, setUnauthorizedIsOpen] = React.useState(false)
  let [isOverToken, setIsOverToken] = React.useState('')
  let [isOpen, setIsOpen] = React.useState(true);
  let [isTokenCopied, setIsTokenCopied] = React.useState(false)

  const [onConfirmTrue, isThrottleReady] = useThrottle(() => {
    setConfirmationIsOpen(false)
    setPreloaderIsOpen(true)
    onConfirm()
  }, 50);

  React.useEffect(() => {
    // call api only if modal is openend
    if (!open) {
      return
    }
    function doFetchCstValue() {
      if (!confirmationIsOpen && status && preloaderIsOpen) {
        setConfirmationIsOpen(false)
        setPreloaderIsOpen(false)
        if (status === 'fulfilled') {
          setSuccessIsOpen(true)
          setUnauthorizedIsOpen(false)
        } else if (status === 'unauthorized') {
          setSuccessIsOpen(false)
          setUnauthorizedIsOpen(true)
        } else {
          setSuccessIsOpen(false)
          setUnauthorizedIsOpen(false)
        }
      }
    }
    doFetchCstValue()
  }, [open, confirmationIsOpen, preloaderIsOpen, status])
  const onClose = () => {
    reset()
  }

  const onUnauthorizedClose = () => {
    dispatch(resetToken())
  }

  const reset = () => {
    onCancel()
    setTimeout(() => {
      setConfirmationIsOpen(true)
      setSuccessIsOpen(false)
      setUnauthorizedIsOpen(false)
      setPreloaderIsOpen(false)
    }, 200)
  }

  const icon = (confirmationIsOpen || preloaderIsOpen)
  ? (
    <ExclamationIcon className="h-20 w-20 text-red-600 mt-10" aria-hidden="true" />
  ) 
  : (
    successIsOpen
    ? (
      <ExclamationIcon className="h-20 w-20 text-yellow-500 mt-10" aria-hidden="true" />
    )
    : (
      <XCircleIcon className="h-20 w-20 text-red-600 mt-10" aria-hidden="true" />
    )
  )

  const content = (confirmationIsOpen || preloaderIsOpen)
  ? (
    <div className="text-md text-gray-500">
      <p className="mt-2">
        <span>If the Fluid Monitor was already configured before and there are scans in progress, the processing will be 
        terminated.</span> 
        <span>Do you want to create a new password?</span>
      </p>
    </div>
  )
  : (
    successIsOpen
    ? (
      <div>
        <p className="mt-8 md:mt-2 font-bold">Previously created password is not valid anymore.</p>
        <p>Please copy these credentials and paste them in the appropriate field of the Fluid Monitor.</p>
      </div>
    )
    : unauthorizedIsOpen
      ? (
        <p>Session has expired. Please sign in.</p>
      )
      : (
        <p>Something went wrong. Please try again. If you keep getting an error, please contact RetInSight support. </p>
      )
  )

  const buttons = (confirmationIsOpen || preloaderIsOpen)
  ? (
    <>
      <button
        type="button"
        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-8 py-2 my-3 md:my-0 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
        onClick={onClose}
        ref={buttonRefConfirmCancel}
        data-testid="modal.question.button.cancel"
        disabled={!confirmationIsOpen}
      >
        Go back
      </button>
      <button
        type="button"
        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-8 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
        onClick={onConfirmTrue}
        ref={buttonRefConfirmOk}
        data-testid="modal.question.button.save"
        disabled={!confirmationIsOpen}
      >
        Continue
      </button>
    </>
  ) 
  : (
    successIsOpen
    ? (
      <button
        type="button"
        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-8 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
        onClick={onClose}
        ref={buttonRefSuccessCancel}
        data-testid="modal.question.button.cancel"
      >
        Close
      </button>
    )
    : unauthorizedIsOpen
      ? (
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-8 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={onUnauthorizedClose}
          ref={buttonRefErrorCancel}
          data-testid="modal.question.button.cancel"
        >
          Close & Sign In
        </button>
      )
      : (
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-8 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={onClose}
          ref={buttonRefErrorCancel}
          data-testid="modal.question.button.cancel"
        >
          Close
        </button>
      )
  )

  const DisplayMessage = ({tokenType = ''}) => {
    return (
      <div className="absolute inset-0 flex justify-center items-center z-10 rounded-lg border bg-gray-700 bg-opacity-75">
        { isTokenCopied 
          ? (
            <p className="text-white">Copied</p>
          )
          : (
            <p className="text-white">Click to copy {tokenType}</p>
          )
        }
      </div>
    )
  }
  
  DisplayMessage.propTypes = {
    tokenType: PropTypes.string,
  }

  const RenderDisplayCustomerToken = (tokenType, token) => {
    return (
      <div
        className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 bg-gray-50 hover:bg-green-100"
        onClick={() => {
          navigator.clipboard.writeText(token)
          setIsTokenCopied(true)
        }}
      >
          <div className="flex-1 min-w-0">
            <button className="focus:outline-none">
              {isOverToken && isOverToken === token && <DisplayMessage tokenType={tokenType} />}
              <span className="absolute inset-0" aria-hidden="true" />
              <p className="text-left text-xl font-medium text-gray-900 text-clip w-3/5 md:w-full">{tokenType}</p>
              <p className="text-sm text-gray-500 truncate text-clip w-2/5 md:w-full">{token}</p>
            </button>
          </div>
          <div className="flex-shrink-0 bg-green">
            <ClipboardCopyIcon className="h-10 w-10" stroke="#aaa" aria-hidden="true"/>
          </div>
      </div>
    )
  }

  return (
    <Transition.Root show={open} as={React.Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={buttonRefConfirmCancel}
        open={open || isOpen}
        onClose={() => setIsOpen(false)} 
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-3xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
              <div className="bg-white p-8">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-20 w-20 rounded-full bg-white sm:mx-0 sm:h-20 sm:w-20 -mt-4">
                    {/* <ExclamationIcon className="h-6 w-6 text-green-600" aria-hidden="true" /> */}
                    {/* <QuestionMarkCircleIcon className="h-20 w-20 text-blue-600" aria-hidden="true" /> */}
                    {icon}
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    {/* 
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      {title}
                    </Dialog.Title>
                     */}
                    <div className="mt-2">
                      {content}
                    </div>
                  </div>
                </div>
                { (preloaderIsOpen && isThrottleReady)
                  ? (
                    <div className="absolute inset-0 flex justify-center items-center z-10 rounded-lg border border-gray-800 bg-gray-700 bg-opacity-75">
                      <RefreshIcon className="self-center text-gray-100 flip-horizontal w-16 h-16 bg-transparent rounded-md spin-back" />
                    </div>
                  ) 
                  : (
                    <></>
                  )
                }
                { successIsOpen
                  ? (
                    <>
                      <div className="mt-4 sm:flex sm:items-start">
                        <div
                          className="w-full"
                          onMouseEnter={() => setIsOverToken(sub)}
                          onMouseLeave={() => {
                            setIsOverToken('')
                            setIsTokenCopied(false)
                          }}
                        >
                          {RenderDisplayCustomerToken('Customer ID', sub)}
                        </div>
                      </div>
                      <div className="mt-2 sm:flex sm:items-start">
                        <div
                          className="w-full"
                          onMouseEnter={() => setIsOverToken(appPassword)}
                          onMouseLeave={() => {
                            setIsOverToken()
                            setIsTokenCopied(false)
                          }}
                        >
                          {RenderDisplayCustomerToken('Customer password', appPassword)}
                        </div>
                      </div>
                    </>
                  )
                  : (
                    <></>
                  )
                }
              </div>
              <div className="bg-gray-50 px-4 pt-0 pb-3 md:py-3 sm:px-6 sm:flex sm:flex-row justify-end mb-2">
                {buttons}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

ModalCreateFMPassword.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onOpen: PropTypes.bool,
  // onSuccess: PropTypes.func,
  data: PropTypes.exact({
    status: PropTypes.string,
    sub: PropTypes.string,
    appPassword: PropTypes.string,
  }),
}

export default ModalCreateFMPassword
